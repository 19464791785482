.main_section {
  width: 80%;
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  &__header {
    width: 85%;
    &__title {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 10px;
      & p {
        text-align: center;
        width: 100%;
        font-size: var(--paragraph-1);
        &#blue {
          font-weight: 700;
          font-size: var(--header-2);
          color: var(--blue);
        }
      }
    }
  }

  &__image_container {
    width: 100%;
    margin-top: 50px;
    display: flex;
    justify-content: center;
    position: relative;

    &__images {
      position: absolute;
      width: 100%;
      height: 100%;

      &__top_left,
      &__top_right,
      &__bottom_left,
      &__bottom_right {
        position: absolute;
        max-width: 10%;
        transform: translate(-130%, -130%);
      }

      &__top_left {
        top: 0;
        left: 0;
      }

      &__top_right {
        top: 0;
        right: 0;
        rotate: 90deg;
      }

      &__bottom_left {
        bottom: 0;
        left: 0;
        rotate: 270deg;
      }
      &__bottom_right {
        bottom: 0;
        right: 0;
        rotate: 180deg;
      }
    }
  }

  &__explore_now {
    margin-top: 50px;
    width: 85%;
    position: relative;
    z-index: 10;
    display: flex;
    justify-content: center;

    &__button {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 10px;
      cursor: pointer;
    }
  }

  @media (max-width: 768px) {
    width: 90%;
    margin-top: 65px;
  }

  &__images {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 20%;
    &__flower {
      position: absolute;
      top: 0;
      right: -15%;
      max-width: 15%;
    }

    &__stars {
      position: absolute;
      top: 0;
      left: -15%;
      z-index: -1;
      max-width: 15%;
    }

    &__star {
      position: absolute;
      max-width: 15%;
      top: 30%;
      left: -15%;
    }
  }
}
