.discord_section {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  &__header {
    width: 85%;
    &__title {
      width: 100%;
      display: flex;
      flex-direction: column;
      text-align: center;
      font-size: var(--header-2);
      color: var(--blue);
      font-weight: 700;
    }

    &__subtitle {
      width: 100%;
      display: flex;
      flex-direction: column;
      text-align: center;
      font-size: var(--paragraph-1);
      margin-top: 14px;
    }
  }

  &__discord_button {
    width: 85%;
    margin-top: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    & button {
      display: flex;
      align-items: center;
      gap: 10px;
      background-color: var(--blue);
      color: white;
      padding: 14px 33px;
      border-radius: 15px;
      cursor: pointer;
    }
  }
}
