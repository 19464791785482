.updates_section {
  margin-top: 100px;
  width: 85%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  &__header {
    width: 85%;
    &__title {
      width: 100%;
      display: flex;
      flex-direction: column;
      text-align: center;
      font-size: var(--header-2);
      color: var(--blue);
      font-weight: 700;
    }
  }

  &__updates {
    width: 85%;
    margin-top: 40px;
    display: flex;
    align-items: center;
    flex-direction: column;
    max-height: 350px;
    overflow-y: auto;
    scroll-behavior: smooth;
    padding: 20px 0;
    &::-webkit-scrollbar {
      display: none;
    }
    gap: 30px;
    &__item {
      display: flex;
      align-items: center;
      gap: 10px;
      background-color: var(--yellow);
      border: 1px solid black;
      padding: 14px 33px;
      border-radius: 15px;
      text-align: center;
      width: 300px;
      position: relative;
      z-index: 10;


      @media screen and (max-width: 425px){
        width: 200px;
      }
    }
  }
}
