.line {
  width: 90%;
  border: 1px solid #ebebeb;
  margin: 10px 0;
}

.landing_page {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 100px;
  &__announcement,
  &__form,
  &__discord {
    width: 85%;
  }

  &__updates {
    width: 100%;
    border-top: 1px solid #000;
    display: flex;
    justify-content: center;
    position: relative;
    &__images {
      position: absolute;
      width: 100%;
      height: 100%;

      &__left {
        position: absolute;
        max-width: 10%;
        top: 50%;
        left: 0;
      }
      &__right {
        position: absolute;
        max-width: 10%;
        top: 50%;
        right: 0;
      }
    }
  }
}
