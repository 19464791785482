@import url("https://fonts.googleapis.com/css2?family=Inter:slnt,wght@-10..0,100..900&display=swap");
:root {
  /* Colors */
  --background-color: #ffffff;
  --blue: #2087ff;
  --dark-blue: #0052bd;
  --light-blue: #cbdffe;
  --grey: #f4f4f4;
  --dark-grey: #e2e2e2;
  --darker-grey: #777777;
  --red: #ff3b20;
  --light-red: #ffaaaa;
  --orange: #ff6f1f;
  --yellow: #fff175;

  /* Sizes */

  --header-1: 50px;
  --header-2: 40px;
  --paragraph-1: 20px;
  --paragraph-2: 16px;

  --infinity: 999999999999999999999px;
  @media screen and (max-width: 768px) {
    --header-1: 25px;
    --header-2: 22px;
    --paragraph-1: 18px;
    --paragraph-2: 14px;
  }
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-size: 16px;
  font-family: Inter;
}


#root {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-x: hidden;
}