@import url("https://fonts.googleapis.com/css2?family=Just+Another+Hand&display=swap");

.footer {
  width: 100%;
  background-color: #f5f9ff;
  display: flex;
  justify-content: center;
  margin-top: 100px;
  border-top: 1px solid #000;
  &__content {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 80%;
    margin-top: 40px;
    margin-bottom: 35px;

    &__left {
      width: 50%;
      font-size: 30px;
      font-family: "Just Another Hand", cursive;
      font-style: normal;
    }

    &__right {
      width: 50%;
      display: flex;
      justify-content: flex-end;
      &__items {
        display: flex;
        gap: 50px;

        &__item {
          cursor: pointer;
          text-decoration: none;
        }
      }
    }
  }
  @media (max-width: 768px) {
    margin-top: 100px;
    &__content {
      flex-direction: column-reverse;
      gap: 20px;

      &__left {
        width: 100%;
        margin-top: 10px;
      }

      &__right {
        width: 100%;
        &__items {
          width: 100%;
          justify-content: flex-start;
          flex-direction: column;
          gap: 20px;
        }
      }
    }
  }
}

.footer-item {
  display: flex;
  align-items: center;
  gap: 10px;
  text-decoration: none;
  color: black;
}
